var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-control",attrs:{"placeholder":"Search by primary option, description or name"},domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filter=$event.target.value}}})]),_c('div',{staticClass:"col"},[_c('b-button',{staticClass:"pull-right",attrs:{"variant":"primary"},on:{"click":_vm.exportExcel}},[_vm._v("Export excel")])],1)]),_c('b-table',{attrs:{"hover":"","responsive":"","small":"","bordered":"","items":_vm.tableData,"fields":_vm.columns,"filter":_vm.filter,"filter-included-fields":['dsgOptions','description','explanation'],"per-page":_vm.pagination.perPage,"current-page":_vm.pagination.currentPage},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(dsgOptions)",fn:function(ref){
var item = ref.item;
return [(item.dsgOptions)?_c('span',[_vm._v(" "+_vm._s(item.dsgOptions.name))]):_vm._e()]}},{key:"cell(createdAt)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDateShort")(item.createdAt))+" ")]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("software_hardware")(item.type))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{attrs:{"variant":"dark"},on:{"click":function($event){return _vm.modalUpdate(item)}}},[_vm._v("Update")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v("Delete")])]}}])}),_c('b-pagination',{staticClass:"pull-right",attrs:{"total-rows":_vm.pagination.rows,"per-page":_vm.pagination.perPage},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}}),_c('b-modal',{attrs:{"id":"modal-update-dsg","title":"Update dsg secondary options","hide-footer":""}},[_c('labe',[_vm._v("Name")]),(_vm.dsgSelected)?_c('b-input',{model:{value:(_vm.dsgSelected.description),callback:function ($$v) {_vm.$set(_vm.dsgSelected, "description", $$v)},expression:"dsgSelected.description"}}):_vm._e(),_c('label',[_vm._v("Description")]),(_vm.dsgSelected)?_c('b-input',{model:{value:(_vm.dsgSelected.explanation),callback:function ($$v) {_vm.$set(_vm.dsgSelected, "explanation", $$v)},expression:"dsgSelected.explanation"}}):_vm._e(),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"dark","block":""},on:{"click":_vm.updateOption}},[_vm._v("Update and add option")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }