import Vue from 'vue';
import {get, post} from '@/services/api';
import {newProducts, updateProduct} from '../../../services/endpoint.json';
import Vehicles from './Vehicles.vue'
import Dsg from './Dsg'
export default Vue.extend({
  name: 'NewProductsPage',
  components:{
    Vehicles,
    Dsg
  },
  data: () => ({
    columns: [
      {key:'id', label: 'ID', sortable:true},
      {key:'vehicleOptions', label: 'Primary Option', sortable:true},
      {key:'type', label: 'Type', sortable:true},
      {key:'description', label: 'Name', sortable:true},
      {key:'explanation', label: 'Description', sortable:true},
      {key:'createdAt', label: 'Created at', sortable:true},'actions'],
    tableData: [],
    tableDsg: [],
    userId: null,
    showDetail: false,
  }),
  mounted() {
    this.getData();
    this.getDataDsg()
  },
  methods: {
    async getData() {
      let {data} = await get(newProducts, null, true);
      if (Number(data.statusCode) === Number(200)) {
        this.tableData = data.message;
      }
    },
    async getDataDsg() {
      const {data} = await get( "dsg-options-two/waiting-approval", null, true);
      this.tableDsg = data;
    },
  },
});
