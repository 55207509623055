<template>
  <div>
    <div class="row mb-2">
      <div class="col">
        <input class="form-control" v-model="filter" placeholder="Search by primary option, description or name">
      </div>
      <div class="col">
        <b-button @click="exportExcel" class="pull-right" variant="primary">Export excel</b-button>
      </div>
    </div>
    <b-table hover responsive small bordered
             :items="tableData"
             :fields="columns"
             :filter="filter"
             :filter-included-fields="['dsgOptions','description','explanation']"
             @filtered="onFiltered"
             :per-page="pagination.perPage"
             :current-page="pagination.currentPage">
      <template #cell(dsgOptions)="{item}">
        <span v-if="item.dsgOptions"> {{ item.dsgOptions.name }}</span>
      </template>
      <template #cell(createdAt)="{item}">
        {{ item.createdAt | localDateShort }}
      </template>
      <template #cell(type)="{item}">
        {{ item.type | software_hardware }}
      </template>
      <template #cell(actions)="{item}">
        <b-button variant="dark" @click="modalUpdate(item)">Update</b-button>
        <b-button variant="danger" @click="deleteItem(item.id)">Delete</b-button>
      </template>
    </b-table>
    <b-pagination
      class="pull-right"
      v-model="pagination.currentPage"
      :total-rows="pagination.rows"
      :per-page="pagination.perPage"
    ></b-pagination>
    <b-modal id="modal-update-dsg" title="Update dsg secondary options" hide-footer>
      <labe>Name</labe>
      <b-input v-if="dsgSelected" v-model="dsgSelected.description"></b-input>
      <label>Description</label>
      <b-input v-if="dsgSelected" v-model="dsgSelected.explanation"></b-input>
      <b-button class="mt-2" variant="dark" block @click="updateOption">Update and add option</b-button>
    </b-modal>
  </div>
</template>

<script>
import {get, post} from "@/services/api";
import {updateProduct} from "@/services/endpoint.json";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "DsgPage",
  mixins: [show_alert_mixin],
  props: {
    tableData: {
      default: []
    },
  },
  data: () => ({
    dsgSelected: null,
    pagination: {
      currentPage: 1,
      perPage: 20,
      rows: 0
    },
    filter: null,
    columns: [
      {key: 'id', label: 'ID', sortable: true},
      {key: 'dsgOptions', label: 'Primary option', sortable: true},
      {key: 'type', label: 'Type', sortable: true},
      {key: 'description', label: 'Name', sortable: true},
      {key: 'explanation', label: 'Description', sortable: true},
      {key: 'createdAt', label: 'Created at', sortable: true}, 'actions'],
  }),
  methods: {
    onFiltered(filteredItems) {
      this.pagination.rows = filteredItems.length === this.tableData.length ? this.tableData.length : filteredItems.length
      this.pagination.currentPage = 1
      this.pagination.perPage = filteredItems.length === this.tableData.length ? 20 : filteredItems.length
    },
    modalUpdate(item) {
      this.dsgSelected = item
      this.$bvModal.show('modal-update-dsg')
    },
    async updateOption() {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        html: 'Are you sure? <br> <br> You will create a new item and it\'ll be available for all vehicles.\n' +
          'Exceptions should be applied directly in the Excel.',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            const {data} = await post("dsg-options-two/update", this.dsgSelected, true);
            if (data === 200) {
              this.$emit('reloadDataDsg')
              this.showAlertNotification("Item added")
            } else {
              this.showAlertNotification('There is a product with the same name', 'error')
            }
            this.$bvModal.hide('modal-update-dsg')
            this.$emit('reloadDataDsg')
          }
        });
    },
    deleteItem(id) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        html: 'Are you sure? <br> <br> The item will be deleted from the database',
        confirmButtonColor: "#d14343",
        cancelButtonColor: "#000000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await post("dsg-options-two/delete", {id: id}, true);
            this.$emit('reloadDataDsg')
            this.showAlertNotification("'Item deleted'")
          }
        });
    },
    async exportExcel() {
      get(`dsg-options-two/export-excel`,
        null, true, 'arraybuffer')
        .then(async ({data}) => {
          const fileURL = window.URL.createObjectURL(new Blob([data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'dsg_products.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loaderManager(false);
        }).catch(() => {
        this.loaderManager(false);
      });
    }
  },
  watch: {
    'tableData'() {
      this.pagination.rows = this.tableData.length
    }
  }
}
</script>

<style scoped>

</style>
